#root {
  min-height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.patient-data {
  color: #6b7280;
  font-size: 0.9rem;
  padding-top: 0.5rem;
}

.patient-data > span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.patient-data > span:first-child {
  margin-left: 0;
}

.patient-data > span:last-child {
  margin-right: 0;
}

.blood-pressure {
  margin-left: 1.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.low-risk,
.last-pressure,
.risk__BAJO,
.pressure__low {
  color: #555;
  font-weight: 600;
}

.high-risk,
.risk__ALTO,
.pressure__high,
.high-pressure {
  color: #d00000;
  font-weight: 600;
}

.high-risk {
  font-weight: 600;
}

.border-danger {
  border: 2px solid #d00000;
  padding: 0.2rem;
}

.warning {
  color: #795600;
  font-weight: 600;
}

.risk-factors input[type="checkbox"]:checked + label {
  font-weight: 600;
}

.risk-factors input[type="checkbox"]:disabled:checked + label {
  font-weight: 600;
  color: #000;
}
